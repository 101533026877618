//@import '../scss/colors.scss';

.profile-container {

    .profile-banner-image {
        position: absolute;
        top: -9px;
        left: 0;
        width: 100%;
        height: 600px;
        background-image: url('https://cherryspins.b-cdn.net/images/background-profile.png');
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        opacity: 1;
    }

    @media (max-width: 767px) {
        .profile-banner-image {
            top: -65px;
            background-image: url('https://cherryspins.b-cdn.net/images/background-profile-mobile.png');
            background-size: contain;
        }
    }

    .profile-section {
        padding: 20px;
        background-color: $skin-foreground;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {
        .profile-section {
            padding: 15px;
        }
    }

    .profile-total-balance {
        display: flex;
        justify-content: space-between;
    }

    .profile-total-balance-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
        margin-bottom: 0px;
    }

    .hero-wrapper {
        // background-color: $skin-foreground-2x;
        margin-top: 10px;
        border-radius: $border-radius-card;
        padding: 15px 0;
        padding-bottom: 0px;
        text-align: center;
        // background: linear-gradient(167deg, rgba(39, 39, 39, 1) 32%, $skin-supplementary 100%);

        @media (max-width: 575px) {
            padding-bottom: 0px;
        }
    }

    .hero-wrapper h1 {
        margin-top: 25px;
        margin-bottom: 40px;

        @media (max-width: 575px) {
            margin-top: 0px;
            margin-bottom: 15px;
        }
    }

    .profile-nav-sticky {
        position: relative;
        top: -1px;
        left: 0;
        width: 100%;
        z-index: 1003;
        // background: $skin-foreground;
        border-top: none;
        border-bottom: none;
        padding: 0;
    }

    @media (max-width: 575px) {
        .profile-nav-sticky {
            top: 1px;
        }
    }

    .profile-nav {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        max-width: 720px;
        margin: auto;
        // margin-top: 15px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .profile-nav::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .profile-nav {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    @media (max-width: 991px) {
        .profile-nav {
            justify-content: flex-start;
            overflow: auto;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .profile-nav-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        position: relative;
        text-align: center;
        padding: 0.5rem 1rem;
        margin: 0 0.25rem;
        text-decoration: none;
        color: $high-emphasis;
        font-size: 0.875rem;
        border-radius: 25px;
        white-space: nowrap;
        border: none;
        flex-grow: 1;
        font-weight: 500;
        border: 2px solid transparent;
    }

    .profile-nav-item:hover,
    .profile-nav-item:active {
        color: $high-emphasis;
    }

    @media (max-width: 576px) {
        .profile-nav-item {
            font-size: 13px;
            margin: 0;
        }
    }

    .profile-nav-item-active,
    .profile-nav-item-active:hover {
        // background-color: $skin-foreground;
        color: $high-emphasis;
        border: 2px solid $skin-supplementary;
    }

    .outlet-container {
        max-width: 738px;
        margin: auto;
        padding-top: 20px;
    }

    @media (max-width: 767px) {
        .outlet-container {
            padding-top: 20px;
        }
    }

    .form-control,
    .form-select {
        background-color: $skin-background;
    }
}

.bonus-details {
    background-color: #3a3a3a;
    margin: 15px -20px -20px;
    border-radius: 10px;
    padding: 20px;

    @media (max-width: 767px) {
        margin: 15px -15px -15px;
        padding: 15px;
    }
}

.profile-total-balance-promocode {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    margin-bottom: 0px;
}

.profile-wager-bar {
    background-color: #121212;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    position: relative;
}

.profile-wager-bar-fill {
    background-color: #39858a;
    height: 100%;
    width: 0;
    border-radius: 10px;
}

.profile-wager-bar-numbers {
    font-size: 12px;
    position: absolute;
    width: 100px;
    top: 1px;
    left: calc(50% - 30px);
}

.profile-cancel-bonus {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-cancel-bonus {
    color: $skin-primary-light !important;
}