.category-link-image-gradient {
    background-color: $skin-foreground;
    border-radius: $border-radius-category-link;
    padding: 0 0 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    flex-grow: 0.33;
    min-width: 300px;
    min-height: 70px;
    
    @include category-link-hover;
}

.category-link-image-gradient:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .category-link-image-gradient {
        margin-right: 0px;
        justify-content: space-between;
    }

    .category-link-image-gradient:last-child {
        margin-bottom: 0;
    }
}

.category-link-image-gradient img {
    height: 70px;
    border-radius: 0 $border-radius-category-link $border-radius-category-link 0;
    mask-image: linear-gradient(270deg, rgb(0, 0, 0) 40%, transparent 95%);
}

.category-link-image-gradient-overlay-wrapper {
    position: relative
}

.category-link-image-gradient-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}