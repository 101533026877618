.responsible-gaming-wrapper {
    display: flex;
    align-items: center;
    margin-top: 60px;

    @media (max-width: 991px) {
        margin-top: 60px;
        margin-bottom: 0px;
        flex-direction: column-reverse;
    }
}

.responsible-gaming-content-wrapper {
    flex-basis: 50%;
    flex-shrink: 0;
    padding-right: 100px;

    @media (max-width: 991px) {
        flex-basis: 100%;
        padding-right: 0px;
        margin-top: 20px;
        text-align: center;
    }
}

.responsible-gaming-image-wrapper {
    flex-basis: 50%;
    flex-shrink: 0;

    @media (max-width: 991px) {
        flex-basis: 100%;
    }
}

.responsible-gaming-image {
    width: 100%;
    height: auto;
    border-radius: $border-radius-card;
}

.responsible-gaming-logos {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;

    @media (max-width: 991px) {
        margin-top: 30px;
        justify-content: space-evenly;
    }
}

.responsible-gaming-logo {
    height: 40px;
    margin-right: 50px;

    @media (max-width: 991px) {
        margin-right: 0px;
    }
}