/* Skin colors */
$skin-primary: #D93744;
$skin-primary-dark: #6C1D24;
$skin-primary-light: #FF4B5C;
// $skin-secondary: #ECF3F4;
// $skin-secondary-light:#f4fbfc;
// $skin-secondary-dark:#d2dfe1;
$skin-supplementary: #0D7077;
$skin-supplementary-light: #39858a;
$skin-supplementary-dark: #0a565c;
$personalized-text: #25c0cd;

$gold: #E9CC14;

/* Text */
$high-emphasis: rgb(255 255 255 / 87%);
$mid-emphasis: rgb(255 255 255 / 60%);
$disabled-text: rgb(255 255 255 / 38%);

/* Dark theme */
/* Background */
$skin-background: #121212;
$skin-foreground: #272727; //dp04 on background
$skin-foreground-2x: #3a3a3a; // double foreground

/* Greyscale */
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

/* White opacities */
$dp01: rgb(255 255 255 / 5%);
$dp02: rgb(255 255 255 / 7%);
$dp03: rgb(255 255 255 / 8%);
$dp04: rgb(255 255 255 / 9%);
$dp06: rgb(255 255 255 / 11%);
$dp08: rgb(255 255 255 / 12%);
$dp09: rgb(255 255 255 / 13%);
$dp10: rgb(255 255 255 / 17%);

////////////////
/// Color classes
///////////////
$white-100: #fff;
$white-87: rgb(255 255 255 / 87%);
$white-60: rgb(255 255 255 / 60%);
$white-38: rgb(255 255 255 / 38%);

.white-100 {
    color: $white;
}

.white-87 {
    color: $white-87;
}

.white-60 {
    color: $white-60;
}

.white-38 {
    color: $white-38;
}

.personalized {
    color: $personalized-text;
}

.personalized-gold {
    color: $gold;
}

.personalized-red {
    color: $skin-primary-light;
}






// 00dp	0%
// 01dp	5%
// 02dp	7%
// 03dp	8%
// 04dp	9%
// 06dp	11%
// 08dp	12%
// 12dp	14%
// 16dp	15%
// 24dp	16%