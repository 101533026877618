* {
    -webkit-overflow-scrolling: touch;
}

body {
    position: relative;
    background-color: $skin-background;
}

body.modal-open {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
}

////////////////////////
/// Containers
//////////////////////
.game-category-container,
.promotions-container {
    position: relative;
    margin-top: 30px;
}

@media (max-width: 575px) {
    .game-category-container,
    .promotions-container {
        margin-top: 20px;
    }
}

@media (max-width: 991px) {
    .game-row-container {
        padding-right: 0;
    }
}

.section-margin {
    margin-bottom: 40px;

    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
}

.promotion-margin {
    margin-bottom: 20px;
}

////////////////////////
/// Game row
//////////////////////
.game-row-wrapper,
.provider-row-wrapper,
.spotlight-row-wrapper,
.bonus-card-row-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.bonus-card-row-wrapper {
    grid-auto-columns: 200px;
    min-height: 160px;

    & > * {
        margin-right: 10px;
    }
}

@media (min-width: 1200px) {

    .game-row-wrapper {
        grid-auto-columns: 210px;
        min-height: 280px;
    }

    .provider-row-wrapper {
        grid-auto-columns: 210px;
        min-height: 240px;
    }

    .spotlight-row-wrapper {
        grid-auto-columns: 275px;
        min-height: 335px;
    }

    .game-row-wrapper > *,
    .provider-row-wrapper > *,
    .spotlight-row-wrapper > * {
        margin-right: 1.25rem;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    
    .game-row-wrapper {
        grid-auto-columns: 195px;
        min-height: 269px;
    }

    .provider-row-wrapper {
        grid-auto-columns: 195px;
        min-height: 200px;
    }

    .spotlight-row-wrapper {
        grid-auto-columns: 275px;
        min-height: 335px;
    }

    .game-row-wrapper > *,
    .provider-row-wrapper > *,
    .spotlight-row-wrapper > * {
        margin-right: 1rem;
    }
}

@media (max-width: 767px) {

    .game-row-wrapper {
        grid-auto-columns: 175px;
        min-height: 244px;
    }

    .provider-row-wrapper {
        grid-auto-columns: 160px;
        min-height: 200px;
    }

    .spotlight-row-wrapper {
        grid-auto-columns: 175px;
        min-height: 244px;
        // grid-auto-columns: 200px;
        // min-height: 250px;
    }

    .game-row-wrapper > *,
    .provider-row-wrapper > *,
    .spotlight-row-wrapper > * {
        margin-right: 0.75rem;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.game-row-wrapper::-webkit-scrollbar,
.spotlight-row-wrapper::-webkit-scrollbar,
.provider-row-wrapper::-webkit-scrollbar,
.bonus-card-row-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.game-row-wrapper,
.spotlight-row-wrapper,
.provider-row-wrapper,
.bonus-card-row-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

////////////////////////
/// Game list
//////////////////////
.game-list-wrapper {
    width: 100%;
    display: grid;
}

@media (min-width: 1200px) {

    .game-list-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
        gap: 1.5rem;
    }

    .game-list-wrapper > * {
        min-height: 290px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    
    .game-list-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 1rem;
    }

    .game-list-wrapper > * {
        min-height: 264px;
    }
}

@media (max-width: 767px) {

    .game-list-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 0.75rem;
    }

    .game-list-wrapper > * {
        min-height: 244px;
    }
}

////////////////////////
/// Placeholder
//////////////////////
.placeholder {
    background-color: $gray-400;
}

////////////////////////
/// Hiding chat widget
//////////////////////
#tidio-chat,
.bb-feedback-button {
    display: none !important;
}

////////////////////////
/// Placehoder color
//////////////////////
.placeholder {
    background-color: $dp10;
}

// #pragmatic-dga-live {
//     display: none !important;
// }

.pci-input-wrapper {
    text-align: left !important;
}