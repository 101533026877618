.first_deposit_lottery {
    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 0 30px 0 0;
        // background: linear-gradient(90deg, #CF991D 10%, #272727 63%);
        background: #272727;
        //background: linear-gradient(90deg, rgba(58,58,58,1) 0%, rgba(39,39,39,1) 100%);
        border-radius: $border-radius-card;
        z-index: 1001;

        @media (max-width: 1399px) {
            padding: 0 15px 0 0;
        }

        @media (max-width: 767px) {
            // background: linear-gradient(160deg, #CF991D 12%, #272727 70%);
            flex-direction: column;
            padding: 0;
        }
    }

    &-badge {
        font-size: 12px;
        margin-bottom: 3px;
        font-weight: 600;
        color: $gold;
    }

    &-amount {
        margin-right: 50px;

        @media (max-width: 1399px) {
            margin-right: 30px;
        }

        @media (max-width: 767px) {
            margin-right: 0px;
            margin-bottom: 5px;
        }
    }

    &-image-wrapper {
        margin-right: 20px;

        @media (max-width: 767px) {
            margin-right: 0px;
            margin-bottom: 15px;
        }
    }

    &-image {
        border-radius: $border-radius-card;
        max-height: 115px;

        @media (max-width: 767px) {
            width: 100%;
            max-height: none;
        }
    }

    &-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: unset;
            text-align: center;
        }
    }

    &-text-wrapper {

        @media (max-width: 767px) {
            padding: 0 15px;
        }
    }

    &-timer-wrapper {
        display: inline-block;
        border-radius: 10px;
        background-color: rgb(0 0 0 / 30%);
        padding: 3px 15px;
    }

    &-timer {
        font-family: 'Roboto Mono', monospace;
        color: rgb(255 255 255 / 75%);
        font-weight: bold;
    }

    &-buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: center;
        flex-shrink: 0;

        @media (max-width: 767px) {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    &-deposit {
        margin: 0px;
        padding: 0px;
        margin-bottom: 15px;
    }

    &-deposit-container {
        background: #3a3a3a;
        padding: 0px;
        border-radius: 10px;
    }

    &-deposit-content {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &-deposit-content-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-deposit-image {
        width: 100px;
        border-radius: 10px;
        margin-right: 5px;
    }

    &-deposit-image-mobile {
        width: 100%;
        border-radius: 10px;
        // height: 50px;
        // width: 100%;
        // border-radius: 10px 10px 0 0;
        // background-image: url(https://static.vecteezy.com/system/resources/thumbnails/006/240/318/small/luxury-hotel-panorama-water-villa-pier-palm-tree-leaves-white-sand-close-to-blue-sea-seascape-beach-chairs-beds-with-white-umbrellas-summer-vacation-and-holiday-beach-resort-on-tropical-island-photo.jpg);
        // background-repeat: no-repeat;
        // background-position: center bottom;
        // background-size: cover;
    }

    &-deposit-text-wrapper {
        padding: 10px;
    }

    &-deposit-guide {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-arrow {
        position: absolute;
        transform: rotate(21deg);
        height: 54px;
        bottom: -55px;
        right: 120px;

        @media (max-width: 767px) {
            right: 38px;
        }
    }
}