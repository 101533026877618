//@import '../scss/colors.scss';

.history-container {
    .history-section {
        padding: 20px;
        background-color: $skin-foreground;
        border-radius: 10px;
        margin-bottom: 20px;
        
    }

    @media (max-width: 767px) {
        .history-section {
            padding: 15px;
        }
    }

    .btn-history-transaction {
        border: 2px solid $skin-foreground !important;
    }

    .transaction {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        border-bottom: 1px solid $skin-foreground;
    }

    .transaction-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 3px 0;
    }

    .transaction-label {
        font-size: 11px;
    }

    .transaction-value {
        color: $high-emphasis;
    }
}