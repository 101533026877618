.category-link-image {
    background-color: $skin-foreground;
    border-radius: $border-radius-category-link;
    padding: 5px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    flex-grow: 0.33;
    min-width: 300px;
    min-height: 70px;
    
    @include category-link-hover;
}

.category-link-image:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .category-link-image {
        margin-right: 0px;
        justify-content: space-between;
    }

    .category-link-image:last-child {
        margin-bottom: 0;
    }
}

.category-link-image img {
    max-height: 50px;
    max-width: 100px;
}