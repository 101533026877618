////////////
/// Modal background
////////////
.modal-content {
    background-color: $skin-foreground;
}

.modal-footer,
.modal-header {
    border: none;
}

////////////
/// Preventing scrolling on mobile devices
////////////
.offcanvas-backdrop,
.modal-backdrop,
.modal-content {
    touch-action: none;
    -ms-touch-action: none;  
}

////////////
/// Rounded corners for all modals
////////////
.modal-content {
    border-radius: $border-radius-card;
}

////////////
/// Alignment classes for modals
////////////
.align-modal-bottom-center .modal-dialog {
    align-items: flex-start;
}

@media (max-width: 767px) {
    .align-modal-bottom-center .modal-dialog {
        align-items: flex-end;
    }
}

////////////
/// Title and button
////////////
.modal-title {
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $high-emphasis;
    margin-bottom: 0;
}

.modal-header .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
    font-size: 12px;
}
