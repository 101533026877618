@import '../scss/colors.scss';

.more-games {
    position: relative;
    top: 20%;
    left: calc(50% - 40px);
    text-align: center;
    font-weight: 500;
}

.more-games-centered {
    top: calc(50% - 60px);
}

.more-games-icon {
    padding: 13px 24px;
    border-radius: 50%;
    font-size: 1.5rem;
    color: $high-emphasis;
    background-color: $skin-foreground;
    margin-bottom: 0.5rem;
    // border: 2px solid $skin-secondary;
}

.more-games:hover .more-games-icon,
.more-games:active .more-games-icon {
    background-color: $skin-foreground;
}

