.games-row-columns-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.games-row-columns-wrapper>div {
    flex-shrink: 0;
    width: 350px;
    margin-right: 1.25rem;
    // border-right: 1px solid $skin-foreground;
    // padding-right: 1.25rem;

    @media (max-width: 767px) {
        margin-right: 1rem;
        width: 330px;
    }

    @media (max-width: 374px) {
        width: 310px;
    }
}

// .games-row-columns-wrapper > div:last-child,
// .games-row-columns-wrapper > div:nth-last-child(2) {
//     border: none;
// }



/* Hide scrollbar for Chrome, Safari and Opera */
.games-row-columns-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.games-row-columns-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.game-column {
    // padding: 0 20px;
    background-color: rgb(39 39 39 / 60%);
    border-radius: $border-radius-card;
    padding-bottom: 20px;
}

// .game-column > a:last-child {
//     border-radius: 0 0 $border-radius-card $border-radius-card;
//     padding-bottom: 20px;
// }

.game-column-top-row {
    display: flex;
    padding: 20px 0 5px 20px;
    justify-content: space-between;
}

.game-column-wrapper {
    position: relative;
    height: 140px;
    text-decoration: none;
    // border-radius: $border-radius-card;
    width: 100%;

    display: flex;
    padding: 13px 25px;

    transition: 0.1s ease;
    -moz-transition: 0.21s ease;
    -o-transition: 0.1s ease;
    -ms-transition: 0.1s ease;
    -webkit-transition: 0.1s ease;

    @media (min-width: 768px) {
        &:hover {
            background-color: $dp04;
        }
    }

    @media (max-width: 767px) {
        padding: 13px 20px;

        &:active {
            background-color: $dp04;
        }
    }
}

.game-column-wrapper .tag-container-right {
    top: 15px;
    right: 15px;
}

.game-column-wrapper-placeholder {
    height: 120px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.game-column-image-wrapper {
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.game-column-image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    filter: blur(5px);
    border-radius: 10px;
    opacity: 0.61;
}

.game-column-image {
    position: relative;
    width: 98px;
    height: auto;
    z-index: 1;
}

.game-column-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.game-column-text-title {
    margin-bottom: 0;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // color: $black;
    color: $high-emphasis;
    font-weight: 600;
    font-size: 0.875rem;

    @media (max-width: 767px) {
        width: 130px;
    }
}

.game-column-text-provider {
    // color: $gray-500;
    color: $mid-emphasis;
    font-weight: 500;
    margin-bottom: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
}