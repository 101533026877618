@import '../scss/colors.scss';

$navbar-height-desktop: 114px;
$navbar-height-mobile: 112px;
$navbar-height-mobile-logged-in-sm: 88px;
$navbar-height-mobile-logged-in-xs: 77px;

.theme-filter-container {
    margin-bottom: 20px;
    background: $skin-background;
    padding: 15px 0;
    // border-top: 1px solid $skin-secondary;
    // border-bottom: 1px solid $skin-secondary;
}

.theme-filter-container-fixed {
    position: sticky;
    position: -webkit-sticky;
    top: $navbar-height-desktop;
    left: 0;
    width: 100%;
    z-index: 1002;
    background: $skin-background;
    // border-bottom: 1px solid $skin-secondary;
    padding: 10px 0;
}

@media (max-width: 576px) {
    .theme-filter-container-fixed {
        top: $navbar-height-mobile
    }

    .theme-filter-container,
    .theme-filter-container-fixed {
        padding: 10px 7px; 
    }
}

.theme-filter-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 950px;
    margin: auto;
}

.theme-filter {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.theme-filter::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.theme-filter {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media (max-width: 767px) {
    .logged-in-top {
        top: $navbar-height-mobile-logged-in-xs;
    }
}

// @media (max-width: 575px) {
//     .logged-in-top {
//         top: $navbar-height-mobile-logged-in-xs
//     }
// }

.theme-category {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    text-align: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: $skin-background;
    color: $mid-emphasis;
    font-size: 0.875rem;
    border-radius: 25px;
    margin: 0 0.275rem;
    white-space: nowrap;
}

.theme-category:hover {
    color: $high-emphasis;
}

@media (max-width: 576px) {
    .theme-category {
        font-size: 13px;
    }
}

.theme-category-placeholder {
    font-size: 0.875rem;
    border-radius: 25px;
    margin: 0 0.375rem;
    border: none;
    width: 100px;
    height: 37px;
}

.theme-category-active {
    color: $high-emphasis;
    font-weight: 500;
    background-color: $skin-foreground;
}