.deposit-summary {
    padding: 10px;
    padding-right: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-weight: 500;
    border: 2px solid #0d7077;
}

.deposit-summary-promocode {
    max-width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}