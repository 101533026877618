@import '../scss/colors.scss';

.brand {
    margin-right: 2rem;
    margin-left: 0.5rem;
    // padding-top: 0.15rem;
    // padding-bottom: 0.43rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

@media (max-width: 767px) {
    .brand {
        margin-right: 1rem;
        margin-left: 0.25rem;
        // padding-top: 0.2rem;
        // padding-bottom: 0.425rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.logo {
    //width: 175px;
    height: 32px;
}

@media (max-width: 576px) {
    .logo {
        // width: 140px;
        height: 27px;
    }
}

@media (max-width: 350px) {
    .logo {
        // width: 140px;
        height: 24px;
    }
}

.navbar-top {
    position: sticky;
    position: -webkit-sticky;
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // background-color: transparent;
    // border-bottom: 1px solid transparent;
    // background-color: rgb(39 39 39);//$white;
    // border-bottom: 1px solid $skin-background;
    z-index: 1004;
    // background-color: $skin-foreground;//$white;
    // border-bottom: 1px solid $skin-foreground;
    
}

.navbar-color {
    background-color: rgb(39 39 39 / 95%);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    // background-color: $skin-foreground;
    // border-bottom: 1px solid $skin-foreground;
    // -webkit-backdrop-filter: blur(20px);
    // backdrop-filter: blur(20px);
    // background-color: rgb(39 39 39 / 85%);
    // background-color: rgb(18 18 18 / 85%);
}

.navbar-top-logged-in-mobile {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(39 39 39);
    z-index: 1004;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    // border-bottom: 1px solid $skin-background;
}

.navbar-name {
    color: $black;
    color: $high-emphasis;
    font-size: 0.75rem;
    text-decoration: none;
    font-weight: 400;
}

.navbar-bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: $skin-foreground;
    z-index: 1004;
    // border-top: 1px solid $skin-secondary;
    padding: 0;
}

.navbar-link {
    padding: 0 !important;
    margin: 0.2rem 1rem 0rem;
    font-size: 0.875rem;
    // color: $gray-600;
    color: $mid-emphasis;
    text-decoration: none;
    border-bottom: 4px solid transparent;
}

.navbar-bottom-link,
.navbar-bottom-link:hover,
.navbar-bottom-link:active,
.navbar-bottom-link:focus {
    position: relative;
    font-size: 1.125rem;
    line-height: 1.125rem;
    padding: 1rem 0;
    margin: 0;
    flex-grow: 1;
    text-align: center;
    color: $mid-emphasis;
    text-decoration: none;
    background-color: $skin-foreground;
    border: none;
    border-bottom: 2px solid $skin-foreground;
    border-radius: 0;
}

.navbar-bottom-link:active {
    background-color: $dp02;
    border-bottom: 2px solid $dp02;
    color: $high-emphasis;
}

.navbar-bottom-link.navbar-bottom-link-active {
    color: $high-emphasis !important;
    border-bottom: 2px solid $skin-primary;
    font-weight: 500;
}

.navbar-bottom-link-logo>img {
    position: absolute;
    top: 12px;
    left: calc(50% - 14px);
    width: 25px;
    opacity: 0.6;
}

.navbar-bottom-link-logo-active>img {
    opacity: 0.87;
}

.btn-mobile-menu-open,
.btn-mobile-menu-open:active,
.btn-mobile-menu-open:hover,
.btn-mobile-menu-open:focus {
    font-size: 1.125rem;
    line-height: 1.125rem;
    background-color: transparent;
    // border-color: $skin-secondary;
    color: $mid-emphasis;
    border: none;
    padding: 0.75rem 0;
    flex-grow: 1;
    margin: 0;
    border-radius: 0;
    border-bottom: 2px solid $skin-foreground;
}

.btn-mobile-menu-open:active {
    background-color: $dp02;
    border-bottom: 2px solid $dp02;
    color: $high-emphasis;
}

.navbar-profile {
    border-radius: 50%;
    padding: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: $mid-emphasis;
    background-color: transparent;
    border: none;
}

.navbar-profile:active,
.navbar-profile:hover,
.navbar-profile:focus {
    color: $high-emphasis;
    border: none;
}

.navbar-profile:active {
    transform: scale(0.95);
}

.navbar-balance {
    margin-right: 0.5rem;
    border-radius: 55px;
    padding: 0.2rem 1.5rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    color: $black;
    color: $high-emphasis;
    background-color: transparent;
    border: 2px solid $skin-primary;
}

.navbar-balance:hover,
.navbar-balance:active,
.navbar-balance:focus {
    background-color: $skin-primary;
    border-color: $skin-primary;
    color: $white;
}

.navbar-balance-mobile {
    color: $black;
    color: $high-emphasis;
    font-size: 0.75rem;
    text-decoration: none;
    padding: 0;
    font-weight: 400;
}

.navbar-bottom-balance {
    background-color: $white;
    margin: 0 0.3rem 0.455rem;
    border: none;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 500;
    color: $black;
    padding: 0.2rem 0;
    flex-grow: 0.5;
}

.navbar-link:hover,
.navbar-link:active {
    // color: $gray-600;
    color: $high-emphasis;
}

.navbar-link-active {
    // color: $black !important;
    color: $high-emphasis !important;
    border-bottom: 2px solid $skin-primary;
    font-weight: 500;
}

@media (max-width: 767px) {

    .navbar-link-active,
    .navbar-link-active:hover,
    .navbar-link-active:active {
        border-bottom: 2px solid $skin-primary;
    }
}

.mobile-menu-logo-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
}

.mobile-menu-logo {
    width: 60%;
    flex-shrink: 0;
}

.mobile-menu {
    max-width: 90%;
    background-color: $skin-foreground;
}

.mobile-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.btn-profile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.mobile-menu-link {
    margin: 0;
    font-size: 16px;
    color: $mid-emphasis;
    text-decoration: none;
    padding: 15px 0 15px 30px;
}

.mobile-menu-link:hover,
.mobile-menu-link:active,
.mobile-menu-link:focus {
    color: $high-emphasis;
}

.mobile-menu-link-active {
    color: $high-emphasis;
    font-weight: 600;
}

.mobile-menu-balance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;
    padding: 18px 15px;
    // background-color: $white;
    border-radius: 10px;
    font-size: 15px;
    text-decoration: none;
    // border-top: 1px solid rgb(255 255 255 / 20%);
    // border-bottom: 1px solid rgb(255 255 255 / 20%);
    border: 2px solid $dp08;
}

.mobile-menu-balance:active {
    background-color: $dp04;
}

.player-name {
    margin: 0;
    font-weight: 500;
    color: $high-emphasis;
    margin-bottom: 5px;
}

.player-balance {
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    color: $mid-emphasis;
}

.mobile-menu-balance-arrow {
    color: $mid-emphasis;
}

.btn-mobile-menu-close,
.btn-mobile-menu-close:hover,
.btn-mobile-menu-close:active,
.btn-mobile-menu-close:focus {
    background-color: $skin-foreground;
    color: $mid-emphasis;
    padding: 30px 0 30px 30px;
    width: 100%;
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    border: none;
    text-align: left;
    border-radius: 0;
}

.btn-mobile-menu-close:active {
    background-color: $dp02;
    color: $high-emphasis;
}

// .btn-mobile-menu-close:active,
// .btn-mobile-menu-close:focus {
//     background-color: $skin-foreground;
//     color: $high-emphasis;
// }

.btn-mobile-menu-register,
.btn-mobile-menu-login,
.btn-mobile-menu-deposit {
    margin: 0 15px 0 15px;
    // font-weight: 700;
    font-size: 0.875rem;
    font-size: 15px;
}

.btn-mobile-menu-register {
    border: 2px solid $dp06 !important;
}

///////////////
/// Game page navigation
//////////////
.navbar-game-link,
.btn-add-funds {
    padding: 0.25rem 1.5rem;
}

.navbar-back-links .navbar-game-link:first-child {
    margin-right: 15px;
}

.navbar-game {
    height: 50px;
    position: absolute;
    width: 100%;
    z-index: 1005;
    padding: 0;

    @media (max-width: 1199px) {
        background: $skin-background;
        position: fixed;
        top: 0;
        left: 0;

        &-portrait {
            height: 35px;
            width: 100%;

            .navbar-game-wrapper {
                flex-direction: row;
            }

            .navbar-back-links .navbar-game-link:first-child {
                margin-left: 15px;
            }

            .btn-add-funds {
                margin-right: 15px;
            }

            .navbar-game-link {
                padding: 0rem 1.5rem;
            }
        }

        &-landscape {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 35px;

            .navbar-game-wrapper {
                flex-direction: column;
            }

            .navbar-nav {
                flex-direction: column;
            }

            .navbar-back-links .navbar-game-link:first-child {
                margin-top: 15px;
                margin-bottom: 15px;
                margin-right: 0;
            }

            .btn-add-funds {
                margin-bottom: 15px;
            }

            .navbar-game-link {
                padding: 1rem 0.25rem;
            }
        }
        
    }

    .navbar-game-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    @media (min-width: 1200px) {
        .navbar-game-wrapper {
            max-width: calc((100vh - 200px) * 1.777);
        }
    }

    .navbar-game-link {
        background-color: $dp04;
        border: none;
        text-decoration: none;
        color: $high-emphasis;
        border-radius: 25px;
        text-align: center;
        font-size: 1rem;
        line-height: normal;
    }

    .navbar-game-link:active {
        background-color: $dp06;
    }

    @media (max-width: 1199px) {
        .navbar-game-link {
            background-color: $dp04;
            font-size: 0.875rem;
            line-height: 1.375rem;
        }
    }

    .btn-add-funds {
        font-size: 14px;
        position: relative;
    }

    .btn-add-funds-red {
        background-color: $skin-primary;
    }

    .btn-add-funds-alert {
        position: absolute;
        left: 0px;
        top: -3px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $skin-primary;
    }

    @media (max-width: 1199px) {
        .btn-add-funds-alert {
            width: 13px;
            height: 13px;
        }
    }
}

///////////////
/// Quick deposit
//////////////
.quick-deposit {
    background-color: #212121;
    width: 600px;

    .offcanvas-header {
        background-color: #272727;
    }

    .offcanvas-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    @media (max-width: 767px) {
        max-width: 95%;
    }

    .canvas-title {
        font-size: 1rem;
        line-height: 150%;
        letter-spacing: 0.0012em;
        color: $high-emphasis;
        margin-bottom: 0;
    }

    .canvas-header .btn-close {
        filter: invert(1) grayscale(100%) brightness(200%);
        font-size: 12px;
    }

    .close-container {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $skin-foreground;
    }

    .deposit-low-balance-container {
        background-color: $skin-supplementary;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 10px;
    }

    .deposit-section {
        padding: 15px;
        padding-top: 0 !important;
        border-radius: 0 !important;
    }

    .game-balance-wrapper {
        background-color: #272727;
        padding-bottom: 15px;
    }

    .game-balance {
        background-color: #212121;
        padding: 15px 15px;
        // border-radius: 10px;
        margin-bottom: 0px;
        // margin-top: -5px;
    }

    .game-balance-red {
        color: $skin-primary;
    }

    .game-balance-subhead {
        color: $disabled-text;
        font-size: 13px;
    }

    .deposit-icons-container {
        margin-bottom: 20px;
    }
}