.hpf-redirect-summary {
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 15px;
    border: 2px solid #0d7077;
}

.hpf-container {
    position: relative;
    min-height: 333px;
}

.hpf-redirect-container {
    position: relative;
    text-align: center;
    // min-height: 333px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.hpf-loader {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #3a3a3a;
}

.hpf-check-icon {
    color: $personalized-text;
    font-size: 30px;
}

.hpf-card-number-wrapper {
    position: relative;
}

.hpf-card-selector {
    position: absolute;
    right: 0;
    bottom: 10px;
    z-index: 100;
    margin-left: -55px;
    margin-right: 20px;
    font-size: 14px !important;

    @media (max-width: 619px) {
        margin-left: -12px;
        margin-right: 5px;
    }
}

.hpf-card-selector > button {
    border: none !important;
    background: transparent !important;
    padding: 0.45rem 0.75rem;
    padding-top: 1rem;
    color: #b3b3b3;
    font-weight: 400;
}

.hpf-field-container {
    display: flex;
    align-items: flex-end;
    background: #e3e3e3;
    padding-bottom: 5px;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    // filter: brightness(2.2);
    filter: invert(100%);
}

// .hpf-field-container input:-internal-autofill-selected {
//     background-color: transparent !important;
//     color: rgb(255 255 255 / 0.65) !important;
// }

.hpf-field-container input:-webkit-autofill,
.hpf-field-container input:-webkit-autofill:hover, 
.hpf-field-container input:-webkit-autofill:focus, 
.hpf-field-container input:-webkit-autofill:active{
    background-color: #121212 !important;
    color: #121212 !important;
    -webkit-text-fill-color: #495057 !important;
}

.hpf-field-container input:-webkit-autofill{
    background-color: #121212 !important;
    color: #121212 !important;
    -webkit-text-fill-color: #495057 !important;
}

.hpf-field-container input.error.changed {
    color: $skin-primary !important;
}

.hpf-field-container > div > iframe {
    height: 59px;
    width: 100%;
}

.hpf-field-container > #hpf-card-number,
.hpf-field-container > #hpf-card-holder {
    flex-grow: 1;
}

.hpf-field-container .same-row-field {
    flex-basis: 50%;
}

.input-error-container {
    display: flex;
    margin-bottom: 15px;
}

.input-error-container > div {
    flex-basis: 50%;
}

.btn-hpf-deposit,
.btn-hpf-redirect {
    margin-top: 15px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
}

// .btn-hpf-redirect {
//     background-color: $skin-foreground !important;
//     border-color: $skin-supplementary !important;
//     color: $personalized-text;
// }

.hpf-input-error {
    //color: $skin-primary;
    color: #26c0cd;
    font-size: 14px;
    margin-top: 3px;
}

.hpf-error-wrapper {
    display: flex;
    align-items: center;
    background-color: $skin-primary;
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
}

.hpf-error-icon {
    font-size: 20px;
    margin-right: 10px;
}

.hpf-error {
    text-align: left;
    font-weight: 500;
}


// .hpf-view {
//     font-size: 16px;
//     line-height: 1;
// }

// .hpf-view .hpf-form-container {
//     width: 100%;
//     // max-width: 550px;
//     // margin: 50px auto;
//     // background-color: #fff;
//     // padding: 30px;
//     // box-shadow: 0 8px 8px 0px rgb(0 0 0 / 5%);
//     // border-radius: 8px;
//     position: relative;
//     // min-height: 350px;
// }

// // .hpf-view .hpf-form-container .hpf-loader-container {
// //     position: absolute;
// //     top: 0;
// //     right: 0;
// //     left: 0;
// //     bottom: 0;
// //     width: 100%;
// //     height: 100%;
// //     z-index: 2;
// //     display: flex;
// //     align-items: center;
// //     justify-content: center;
// //     background-color: rgba(255, 255, 255, 0.7);
// // }

// // .hpf-view .hpf-form-container .hpf-loader-container .hpf-loader-icon {
// //     display: inline-block;
// //     width: 40px;
// //     height: 40px;
// // }

// // .hpf-view .hpf-form-container .hpf-loader-container .hpf-loader-icon:after {
// //     content: " ";
// //     display: block;
// //     width: 35px;
// //     height: 35px;
// //     margin: 8px;
// //     border-radius: 50%;
// //     border: 6px solid #fff;
// //     border-color: #f0ecec transparent #f0ecec transparent;
// //     animation: lds-dual-ring 1.2s linear infinite;
// // }

// // @keyframes lds-dual-ring {
// //     0% {
// //         transform: rotate(0deg);
// //     }

// //     100% {
// //         transform: rotate(360deg);
// //     }
// // }

// // .hpf-view .hpf-form-container .hpf-title {
// //     text-align: center;
// //     padding-bottom: 15px;
// //     margin: 0;
// //     font-size: 24px;
// //     font-weight: 700;
// // }

// .hpf-view .hpf-form .input-item .hpf-input-error {
//     width: 100%;
//     font-size: 11px;
//     transition: 0.15s all ease;
//     font-style: italic;
//     color: red;
//     height: 15px;
//     opacity: 0;
// }

// .hpf-view .hpf-form .input-item.has-error .hpf-input-error {
//     opacity: 1;
// }

// .hpf-view .hpf-form .btn-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 15px 10px;
// }

// .hpf-view .hpf-form .btn {
//     background-color: rgb(251 146 60);
//     height: 50px;
//     width: 100%;
//     max-width: 240px;
//     border-radius: 10px;
//     border: none;
//     font-size: 16px;
//     font-weight: 700;
//     cursor: pointer;
//     color: white;
//     transition: 0.5s;
//     outline: none;
//     opacity: 1;
// }

// .hpf-view .hpf-form .btn:hover {
//     background-color: rgb(249 115 22);
// }

// .hpf-view .hpf-form .hpf-fields-container {
//     display: flex;
//     flex-direction: column;
//     gap: 0.25rem;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item iframe {
//     height: 55px;
//     width: 100%;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item {
//     position: relative;
//     min-height: 72px;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-list-dropdown {
//     position: absolute;
//     -webkit-user-select: none;
//     user-select: none;
//     width: 30px;
//     height: 30px;
//     padding: 5px;
//     top: 22px;
//     right: 2%;
//     max-height: 100%;
//     display: none;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     opacity: 1;
//     transition: 0.3s;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-list-dropdown.active {
//     rotate: 180deg;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-list-dropdown:hover {
//     background-color: rgba(238, 238, 238, 0.31);
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-list-dropdown .svg-dropdown {
//     width: 15px;
//     height: 15px;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-templates-list {
//     width: 100%;
//     padding: 10px 0;
//     position: absolute;
//     top: calc(100% - 10px);
//     left: 0;
//     border-radius: 4px;
//     box-shadow: 0 2px 3px rgb(0 0 0 / 50%);
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     user-select: none;
//     max-height: 160px;
//     overflow: auto;
//     z-index: 1;
//     background-color: #f8f8f8;
//     display: none;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-templates-list.show {
//     display: block;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-templates-list .hpf-card-list-item {
//     padding: 5px 10px;
//     cursor: pointer;
//     font-size: 14px;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-templates-list .hpf-card-list-item:hover {
//     background-color: #f0ecec;
// }

// .hpf-view .hpf-form .hpf-fields-container .input-item.card_number .card-templates-list .hpf-card-list-item.active {
//     font-weight: 600;
//     background-color: #f0ecec;
// }