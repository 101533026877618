//@import '../scss/colors.scss';

.register-canvas {
    max-width: 90%;
}

.register-canvas {

    .register-canvas-header {
        background-color: $skin-foreground;
    }

    .register-canvas-title {
        font-size: 1rem;
        line-height: 150%;
        letter-spacing: 0.0012em;
        color: $high-emphasis;
        margin-bottom: 0;
    }

    .register-canvas-header .btn-close {
        filter: invert(1) grayscale(100%) brightness(200%);
        font-size: 12px;
    }

    // .register-canvas-header .btn-close {
    //     color: #fff; 
    //     opacity: 1;
    // }

    .register-canvas-body {
        background-color: $skin-foreground;
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .arrow-block-email {
        position: absolute;
        display: flex;
        height: 13px;
        align-items: flex-end;

        img {
            transform: rotate(283deg);
            width: 25px;
            flex-shrink: 0;
        }

        p {
            margin: 0;
            margin-left: 3px;
            margin-bottom: -12px;
            font-weight: 600;
            color: $skin-primary;
            font-size: 12px;
        }
    }

    .promotion-wrapper {
        // display: flex;
        // align-items: center;
        // // background-color: $skin-foreground-2x;
        // padding: 15px 15px;
        // width: 100%;
        // margin-bottom: 30px;
        // border-top: 1px solid $skin-foreground-2x;
        // border-bottom: 1px solid $skin-foreground-2x;
        display: flex;
        align-items: center;
        padding: 15px 15px;
        border: 1px solid #3a3a3a;
        margin: 0 10px;
        border-radius: 10px;
    }

    // @media (max-width: 999px) {
    //     .promotionWrapper {
    //         padding: 15px;
    //     }
    // }

    .promotion-wrapper img {
        width: 65px;
        height: auto;
        border-radius: 7px;
    }

    .form-wrapper {
        position: relative;
        padding: 0 15px;
    }

    .form-wrapper-background {
        padding: 15px;
        background: #3a3a3a;
        border-radius: 10px;
        margin: 10px;
    }

    .login-wrapper {
        padding: 15px;
        border-radius: 10px;
        margin-top: auto;
        margin-bottom: 10px;
    }

    @media (max-width: 767px) {
        .login-wrapper {
            margin-top: auto;
            margin-bottom: 10px;
        }
    }

    .registration-login-message {
        color: $disabled-text;
        font-weight: 500;
    }

    .btn-registration-login {
        width: 100%;
        background-color: transparent;
        border-color: $dp06;
        border-width: 1px !important;
        color: $mid-emphasis;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .progress-container {
        position: relative;
        position: absolute;
        top: 98px;
        width: calc(100% - 30px);
        // width: 100%;
        display: flex;
        align-items: center;
        background-color: transparent;
        margin-bottom: 20px;
        padding-top: 5px;
    }

    .progress-bar {
        position: relative;
        height: 3px;
        width: 100%;
        background-color: $dp08;
        border-radius: 4px;
    }

    .step-1.progress-bar,
    .step-email.progress-bar {
        animation: play 4s ease-in infinite;
        -webkit-animation: play 5s ease-in infinite;
        background-image: linear-gradient(115deg, $dp08 10%, $disabled-text 45%, $dp08 55%);
        background-size: 200%;
    }

    @keyframes play {
        0% {
            background-position: 310%;
        }
        40% {
            background-position: 310%;
        }
        60% {
            background-position: 110%;
        }
        100% {
            background-position: 110%;
        }
    }

    .step-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
    }

    .step-footer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    .registration-arrow-email {
        position: absolute;
        width: 290px;
        top: -15px;
        left: -20px;
    }

    .registration-arrow-address {
        position: absolute;
        width: 290px;
        top: -10px;
        left: 10px;
    }

    .btn-next {
        padding: 12px 80px;
    }

    .btn-back {
        background-color: transparent !important;
        padding: 5px 15px;
        color: $mid-emphasis;
        margin-right: 5px;
    }

    // .progress-star {
    //     font-size: 16px;
    //     color: $disabled-text;
    //     border-radius: 50%;
    //     margin-left: -2px;
    //     border: 3px solid $skin-background;
    //     padding: 6px 10px;
    //     // border: 5px solid $skin-foreground;
    //     // box-shadow: 0 0 0 15px $skin-background;
    // }

    // .star-complete {
    //     color: $skin-primary;
    //     border-color: $skin-primary;
    // }

    .completed-bar {
        position: absolute;
        left: 0%;
        top: 0;
        height: 5px;
        width: 0%;
        background-color: $skin-primary;
        transition: all 0.5s ease-out;
        border-radius: 4px;
    }

    .step-2 .completed-bar {
        width: 25%;
    }

    .step-3 .completed-bar {
        width: 50%;
    }

    .step-4 .completed-bar {
        width: 75%;
    }

    .step-5 .completed-bar {
        width: 95%;
    }

    .step-6 {
        display: none;
    }

    .step-7 {
        display: none;
    }

    .step-8 {
        display: none;
    }

    ///////////////////////////////////

    .step-password .completed-bar {
        width: 25%;
    }

    .step-name .completed-bar {
        width: 50%;
    }

    .step-country .completed-bar {
        width: 65%;
    }

    .step-phone .completed-bar {
        width: 80%;
    }

    .step-addressAutoCanada .completed-bar {
        width: 95%;
    }
    
    .step-addressManualCanada,
    .step-addressManualWorld,
    .step-completeRegistration,
    .step-registrationSuccess {
        display: none;
    }

    .progress-bar-steps {
        position: absolute;
        top: 11px;
        right: 0%;
        padding: 0 0 0 7px;
        font-size: 11px;
        color: $disabled-text;
    }

    .progress-bar-steps.step-6,
    .progress-bar-steps.step-7,
    .progress-bar-steps.step-8 {
        display: none;
    }

    .step-title {
        font-size: 0.875rem;
        line-height: 150%;
        font-weight: 500;
        letter-spacing: 0.0012em;
        color: $disabled-text;
        margin-bottom: 0;
    }

    // .registration-field-label {
    //     color: $disabled-text;
    //     font-size: 0.875rem;
    //     line-height: 150%;
    //     letter-spacing: 0.0012em;
    // }


    .registration-field-phone-code {
        color: $white-60;
        font-weight: 500;
    }

    .registration-field {
        background-color: transparent !important;
        border: none;
        border-radius: 0;
        font-weight: 500;
        padding: 0.75rem 0.75rem;
    }

    .registration-field-address {}

    .form-control::placeholder {
        color: $mid-emphasis;
        font-weight: 500;
    }

    .input-group .form-floating,
    .input-group .form-control {
        flex-grow: 1;
    }

    .input-group-text {
        background-color: transparent;
        border: none;
        padding: 1.25rem 0.75rem;
    }

    // .form-floating>label {
    //     padding: 1.5rem 0.75rem 1rem 0rem;
    //     opacity: 1;
    //     color: $high-emphasis;
    // }

    // .form-floating>.form-control {
    //     padding: 1.5rem 0.75rem 0rem 3px;
    // }

    // .registration-field::placeholder {
    //     color: red !important;
    //     opacity: 1;
    // }

    // workaround
    .intl-tel-input {
        display: table-cell;
    }

    .intl-tel-input .selected-flag {
        z-index: 4;
    }

    .intl-tel-input .country-list {
        z-index: 5;
    }

    .input-group .intl-tel-input .form-control {
        border-top-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 0;
    }


    //////////
    .form-control,
    .form-select,
    .input-group-text {
        // border-top-color: transparent !important;
        // border-left-color: transparent !important;
        // border-right-color: transparent !important;
        // border-radius: 0;
    }

    .form-select-country {
        height: calc(3rem + 2px);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23b0b0b0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }

    .form-control {
        box-shadow: none !important;
    }

    .input-group {
        flex-wrap: nowrap;
    }

    .input-group-text {
        padding-top: 1.325rem;
        // background-color: transparent;
        // border: none;
    }

    .registration-field-country-flag {
        padding-top: 19px;
    }

    .registration-field-country-flag img {
        height: 13px;
        width: 24px;
    }

    // .form-control.is-valid,
    // .was-validated .form-control:valid {
    //     background-position: right 70%;
    // }

    .register-final-loading-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        
    }

    // .form-floating>.form-control,
    // .form-floating>.form-select {
    //     height: calc(4rem + 2px);
    // }

    // .form-floating>label {
    //     padding-top: 1.5rem;
    //     opacity: 0.65;
    // }

    // .form-floating>.form-control:focus~label,
    // .form-floating>.form-control:not(:placeholder-shown)~label,
    // .form-floating>.form-select~label {
    //     opacity: .65;
    //     transform: scale(.85) translateY(-1.325rem) translateX(0.15rem);
    // }

}