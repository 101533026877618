@mixin horizontal_tile {
    &-container {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        background-color: $skin-foreground;
        border-radius: $border-radius-card;
        z-index: 1001;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &-image-wrapper {
        width: auto;
        min-width: 320px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;

        @media (max-width: 767px) {
            min-width: auto;
        }
    }

    &-image {
        width: 100%;
        height: 140px;
        margin-right: 25px;
        border-radius: $border-radius-card;
        margin: 0px auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        align-self: flex-start;

        @media (max-width: 767px) {
            height: auto;
        }
    }

    &-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px 15px 20px;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px 15px;
            align-self: baseline;
            align-items: unset;
        }
    }

    &-buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: center;

        @media (max-width: 767px) {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}

.action_bonus {
    @include horizontal_tile;
}