.loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 70px;
}

.loading div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $skin-primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-supplementary div {
    background: $skin-supplementary;
}

.loading-white div {
    background: $high-emphasis;
}

.loading div:nth-child(1) {
    left: 8px;
    animation: loading1 0.6s infinite;
}

.loading div:nth-child(2) {
    left: 8px;
    animation: loading2 0.6s infinite;
}

.loading div:nth-child(3) {
    left: 32px;
    animation: loading2 0.6s infinite;
}

.loading div:nth-child(4) {
    left: 56px;
    animation: loading3 0.6s infinite;
}

@keyframes loading1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loading3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes loading2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}