////////////
/// Bootstrap
////////////
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

////////////
/// Fonts
////////////
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');



:root {
  --bs-font-sans-serif: Poppins, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

////////////
/// Shared styles
////////////
@import "./scss/colors.scss";
@import './scss/variables.scss';
@import "./scss/typography.scss";
@import "./scss/buttons.scss";
@import "./scss/modal.scss";

@import "./scss/main.scss";

@import 'animate.css';
@import './scss/forms.scss';




////////////
/// Pages
////////////
@import "./pages/deposit.scss";
@import "./pages/details.scss";
@import "./pages/game.scss";
@import "./pages/games.scss";
@import "./pages/history.scss";
@import "./pages/login.scss";
@import "./pages/profile.scss";
@import "./pages/register.scss";
@import "./pages/resetPassword.scss";
@import "./pages/withdraw.scss";
@import "./pages/quickDeposit.scss";
@import "./pages/regionBlocked.scss";

////////////
/// Components
////////////
@import './components/addressLookupRegForm.scss';
@import './components/allGamesRow.scss';
@import './components/categoryLinkIconLeft.scss';
@import './components/categoryLinkIconRight.scss';
@import './components/categoryLinkImage.scss';
@import './components/categoryLinkNoIcon.scss';
@import './components/fallback.scss';
@import './components/filterRow.scss';
@import './components/footer.scss';
@import './components/freespinsRow.scss';
@import './components/gamesRowColumns.scss';
@import './components/gameTile.scss';
@import './components/homeBanner.scss';
@import './components/homeBannerLogout.scss';
@import './components/gamesRowPragmaticLive.scss';
@import './components/scrollButtons.scss';
@import './components/backgroundGradient.scss';
@import './components/scrollButtonsSubcategories.scss';
@import './components/responsibleGaming.scss';
@import './components/jackpotGamesRow.scss';
@import './components/mustDropJackpotRow.scss';
@import './components/totalJackpot.scss';
@import './components/weeklyBonus.scss';
@import './components/praxisHPFDeposit.scss';
@import './components/choosePaymentMethod.scss';
@import './components/depositSummary.scss';
@import './components/loading.scss';
@import './components/actionBonus.scss';
@import './components/categoryLinkImageGradient.scss';
@import './components/firstDepositLottery.scss';
@import './components/limitedTimeFreeBonus.scss';


