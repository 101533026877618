//@import "../scss/colors.scss";

.game-page-body {
    height: calc(100vh + 30px);
    overflow: inherit;
}

.game-page-container {
    min-height: 100vh;
    // background: blue;
    background-color: $skin-background;
}

.game-page-full-hight {
    height: 100vh;
    // background: yellow;
    background-color: $skin-background;
}

.game-page-wrapper {
    top: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto;
    background-color: $skin-background;
}

.game-frame-container {
    padding: 0px;
    height: 100%;
    position: relative;
    -webkit-box-flex: 1;
    flex-grow: 1;
    z-index: 1;
    display: block;
}

@media (min-width: 1200px) {
    .game-frame-container {
        padding: 90px 0;
        display: flex;
        justify-content: center;
    }
}

.game-frame-wrapper {
    cursor: pointer;
    height: 100%;
    width: 100%;
    background-color: $skin-background;
}

@media (min-width: 1200px) {
    .game-frame-wrapper {
        max-width: calc((100vh - 180px) * 1.777);
        height: auto;
    }
}

.game-frame {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    display: block;
    border: none;
    position: relative;
    background-color: $skin-background;
}

.size-portrait .game-frame {
    width: 100%;
    height: calc(100% - 35px);
    top: 35px;
}

.size-landscape .game-frame {
    width: calc(100% - 35px);
    height: 100%;
    left: 35px;
}

/* Portrait orientation */
@media screen and (orientation: portrait) {
    .size-mobile .game-frame {
        width: 100%;
        height: calc(100% - 35px);
        top: 35px;
    }
}

/* Landscape orientation */
@media screen and (orientation: landscape) {
    .size-mobile .game-frame {
        width: calc(100% - 35px);
        height: 100%;
        left: 35px;
    }
}

/////////////
/// Decorative
/////////////
.game-page-background {
    display: none;
}

@media (min-width: 1200px) {
    .game-page-background {
        display: block;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.6;
        animation: fadeInBackground 2s;
        animation-timing-function: ease-out;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        z-index: 0;
    }
}

@keyframes fadeInBackground {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

.swipe-up-overlay {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    height: 3000px;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    z-index: 1;
}

.swipe-up-overlay img {
    height: 150px;
    width: auto;
    animation: dragAnimate 1.5s infinite;
    animation-timing-function: ease-out;
}

@keyframes dragAnimate {

    0%,
    80% {
        -webkit-transform: translate(0, 30)
    }

    0% {
        -webkit-transform: translate(0, 200px)
    }
}

.game-frame-loading {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1200px) {

    .game-frame-wrapper,
    .game-frame {
        border-radius: 10px;
    }
}