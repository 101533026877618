.btn-choose-payment {
    // 
    // // padding: 15px 25px !important;
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    border-radius: $border-radius-category-link;
    justify-content: space-between;
    align-items: center;
    // text-decoration: none;
    // flex-grow: 0.33;
    // width: 100%;
    // border-color: #4e4e4e;
    // margin-bottom: 15px;
    // font-size: 14px;

    & img {
        max-height: 25px;
        max-width: 300px;
        margin: 5px 0;
        margin-right: 13px;
    }
}

.interac img,
.coinspaid img,
.muchbetter img {
    max-height: 35px;
    margin-top: 0;
    margin-bottom: 0;
}

// .choose-payment-mp-wrapper {
//     position: relative;
// }

// .choose-payment-mp-badge {
//     position: absolute;
//     top: -9px;
//     left: calc(50% - 44px);
//     display: inline-block;
//     font-size: 10px;
//     color: $white-100;
//     background-color: $skin-primary;
//     padding: 2px 7px;
//     border-radius: 15px;
// }

// .choose-payment-hr {
//     background-color: $white-60;
// }

.btn-choose-payment {
    font-weight: 400;
    color: $white-100;
    background: #3a3a3a;

    &:hover,
    &:focus,
    &:active {
        background-color: #4e4e4e;
    }
}
