.form-control {
    background-color: $skin-background;
    border: $dp04;
    color: $mid-emphasis;
}

.form-control::placeholder {
    color: $disabled-text;
}

.form-control:focus {
    background-color: $skin-background;
    color: $high-emphasis;
}

.form-floating>label {
    color: $mid-emphasis;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $high-emphasis;
  -webkit-box-shadow: 0 0 0px 1000px #3e3e3e inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control:disabled {
    background-color: $skin-background;
    color: $disabled-text;
}

.form-select {
    background-color: $skin-background;
    color: $mid-emphasis;
    border: $dp04;
}

.form-select:disabled {
    background-color: $skin-background;
    color: $disabled-text;
}

//////////////
/// Accordion
/////////////
.accordion-button::after {
    filter: invert(1) grayscale(100%) brightness(200%);
}

//////////////
/// Alerts
/////////////
.alert {
    padding: 5px 15px;
    font-size: 14px;
}

.alert .btn-close {
    padding: 10px 15px;
    font-size: 12px;
}