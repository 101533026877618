@import '../scss/colors.scss';

.footer-container {
    // background-color: $skin-secondary;
    // background-color: $skin-background;
    background: $skin-background;
    background: -moz-linear-gradient(180deg, $skin-background 1%, $skin-foreground 100%);
    background: -webkit-linear-gradient(180deg, $skin-background 1%, $skin-foreground 100%);
    background: linear-gradient(180deg, $skin-background 1%, $skin-foreground 100%);
    padding: 4rem 0;
    margin-top: 2rem;
}

@media (max-width: 767px) {
    .footer-container {
        padding: 3rem 0 3rem;
    }
}

.footer-logo {
    width: 140px;
    margin-bottom: 2rem;
}

.footer-content {
    display: flex;
    flex-direction: row;
}

@media (max-width: 767px) {
    .footer-content {
        flex-direction: column;
    }
}

.footer-info {
    display: flex;
    flex-direction: column;
    flex-basis: 75%;
    flex-shrink: 0;
    margin-right: 30px;
}

@media (max-width: 767px) {
    .footer-info {
        margin-right: 0px;
        margin-bottom: 1rem;
    }
}

.footer-links {
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    flex-shrink: 0;
    margin-bottom: 0.875rem;
}

.footer-link {
    font-size: 0.875rem;
    line-height: 150%;
    letter-spacing: 0.0012em;
    // color: $gray-600;
    color: $mid-emphasis;
    text-decoration: none;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.footer-link:focus,
.footer-link:hover,
.footer-link:active {
    color: $black;
    color: $high-emphasis;
}

.footer-images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;
    // opacity: 0.6;
}

@media (max-width: 767px) {
    .footer-images {
        margin-top: 1rem;
        justify-content: space-around;
    }
}

.footer-image {
    height: 40px;
    width: auto;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .footer-image {
        height: 30px;
        margin-right: 20px;
        margin-top: 10px;
    }
}

.footer-image-opacity {
    opacity: 0.6;
}