.region_blocked-section {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.region_blocked-description {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}