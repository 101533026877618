.category-link-icon-left {
    background-color: $skin-foreground;
    border-radius: $border-radius-category-link;
    padding: 20px 30px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    flex-grow: 0.33;
    min-width: 350px;
    
    @include category-link-hover;
}

.category-link-icon-left:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .category-link-icon-left {
        min-width: 320px;
        margin-right: 0px;
        justify-content: space-between;
        padding: 15px 30px 15px 20px;
    }

    .category-link-icon-left:last-child {
        margin-bottom: 0;
    }
}

.category-link-icon-left-title {
    font-weight: 500;
}

.category-link-icon-left-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.category-link-icon-left-image-wrapper {
    width: 100px;
    text-align: center;
}

.category-link-icon-left-image {
    height: 35px;
    width: auto;
}
