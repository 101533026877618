////////////////////////
/// Theme scroll arrows
//////////////////////
.arrow-right-theme,
.arrow-left-theme {
    display: none;
    position: absolute;
    right: 0;
    padding: 0.55rem 0.75rem;
    border-radius: 50%;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: $mid-emphasis;
    background-color: $skin-foreground;
    margin: 0rem;
    border: 1px solid transparent;
    box-shadow: 0 0 20px 20px $skin-background;
    z-index: 1002;
}

.arrow-left-theme {
    left:0;
    right: auto;
}

.arrow-left-theme:hover,
.arrow-right-theme:hover,
.arrow-left-theme:active,
.arrow-right-theme:active,
.arrow-left-theme:focus,
.arrow-right-theme:focus {
    background-color: $skin-foreground;
    color: $high-emphasis;
    border: 1px solid transparent;
    // box-shadow: 0 0 20px 20px $skin-background;
}

.arrow-left-theme:active,
.arrow-right-theme:active {
    transform: scale(0.95);
}

/* Hiding the arrows on mobile */
@media (min-width: 992px) {
    .arrow-visible-theme {
        display: block;
    }
}