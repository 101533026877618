@import '../scss/colors.scss';

.filter-row-title-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 15px;
    
}

@media (min-width: 768px) and (max-width: 1399px) {
    .filter-row-title-wrapper {
        margin-right: 110px;
    }
}

@media (max-width: 991px) {
    .filter-row-title-wrapper {
        margin-right: 15px;
    }
}

.filter-row-title-link {
    font-weight: 500;
}

.filter-row-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    order: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.filter-row-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter-row-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}