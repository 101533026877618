@import '../scss/colors.scss';

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: $skin-background;
    z-index: 99999;
}