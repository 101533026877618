////////////////////////
/// Mixins
//////////////////////
@mixin btn {
    text-decoration: none;
    border-radius: 10px;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.5rem 1.75rem;

    transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    -webkit-transition: 0.2s ease;

    @media (max-width: 767px) {
        padding: 0.5rem 1rem;
    }
}

@mixin btn-lg {
    @include btn;
    padding: 1rem 2rem;

    @media (max-width: 767px) {
        padding: 1rem 2rem;
    }
}

@mixin btn-color($color, $background) {
    background-color: $background;
    color: $color;
    border: 2px solid $background;
}

@mixin hover($color, $background) {
    text-decoration: none;
    background-color: $background;
    color: $color;
    border: 2px solid $background;
}

@mixin active {
    transform: scale(0.95);
}

@mixin disabled($color, $background) {
    background-color: $background;
    color: $color;
    border-color: $background;
    opacity: 0.65;
}

////////////////////////
/// Main button
//////////////////////
.btn-main,
.btn-main-lg {
    @include btn;
    @include btn-color($white, $skin-primary);

    &:hover,
    &:active,
    &:focus {
        @include hover($white, $skin-primary);
        -webkit-box-shadow: 0px 0px 15px 0px $skin-primary;
        -moz-box-shadow: 0px 0px 15px 0px $skin-primary;
        box-shadow: 0px 0px 15px 0px $skin-primary;
    }

    &:active {
        @include active
    }

    &:disabled {
        @include disabled($white, $skin-primary)
    }
}

.btn-main-lg {
    @include btn-lg;
}

////////////////////////
/// Secondary button
//////////////////////
.btn-secondary,
.btn-secondary-lg {
    @include btn;
    @include btn-color($mid-emphasis, transparent);
    background-color: transparent;

    &:hover,
    &:active,
    &:focus,
    &.active {
        @include hover($high-emphasis, transparent);
    }

    &:active {
        @include active
    }

    &:disabled {
        @include disabled($disabled-text, transparent)
    }
}

.btn-secondary-lg {
    @include btn-lg;
}

////////////////////////
/// Secondary button with outline
//////////////////////
.btn-secondary-outline,
.btn-secondary-outline-lg {
    @include btn;
    @include btn-color($mid-emphasis, transparent);
    background-color: transparent;
    border: 2px solid $skin-foreground;

    &:hover,
    &:active,
    &:focus,
    &.active {
        @include hover($high-emphasis, transparent);
        border: 2px solid $skin-foreground;
    }

    &:active {
        @include active;
        border: 2px solid $skin-foreground;
    }

    &:disabled {
        @include disabled($disabled-text, transparent);
        border: 2px solid $skin-foreground;
    }
}

.btn-secondary-outline-lg {
    @include btn-lg;
}

////////////////////////
/// Supplementary buttons
//////////////////////
.btn-supplementary,
.btn-supplementary-lg {
    @include btn;
    @include btn-color($white, $skin-supplementary);

    &:hover,
    &:active,
    &:focus {
        @include hover($white, $skin-supplementary)
    }

    &:active {
        @include active
    }

    &:disabled {
        @include disabled($white, $skin-supplementary)
    }
}

.btn-supplementary-lg {
    @include btn-lg;
}

////////////////////////
/// Yellow buttons
//////////////////////
.btn-gold,
.btn-gold-lg {
    @include btn;
    @include btn-color($white, #D79C15);

    &:hover,
    &:active,
    &:focus {
        @include hover($white, #D79C15)
    }

    &:active {
        @include active
    }

    &:disabled {
        @include disabled($white, #D79C15)
    }
}

.btn-gold-lg {
    @include btn-lg;
}