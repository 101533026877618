.weekly-bonus-section {
    padding-top: 24px;
}

.weekly-bonus-tile-container {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    background-color: $skin-foreground;
    border-radius: $border-radius-card;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.weekly-bonus-tile-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 15px 20px;
    z-index: 2;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        align-self: baseline;
        padding: 15px;
        padding-bottom: 20px;
    }
}

.weekly-bonus-tile-buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.weekly-bonus-image-wrapper {
    position: relative;
    width: auto;
    min-width: 320px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    @media (max-width: 767px) {
        width: 100%;
        flex-grow: 1;
    }
}

.weekly-bonus-image {
    width: auto;
    height: $promo-image-height;
    border-radius: $border-radius-card;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    margin: 0px;
    align-self: flex-start;

    @media (max-width: 767px) {
        border-radius: $border-radius-card;
        width: 100%;
        height: auto;
    }
}

.weekly-bonus-badge {
    display: inline-block;
    font-size: 12px;
    color: $gold;
    font-weight: 500;
}

.weekly-bonus-timer-container {
    position: absolute;
    top: -24px;
    left: 0;
    text-align: left;
    width: 100%;
    // background-color: $skin-primary;
    background: linear-gradient(150deg, $skin-primary 30%, $skin-primary-dark 100%);
    padding: 0px 15px 40px 15px;
    border-radius: $border-radius-card $border-radius-card 0 0;
    z-index: -1;

    @media (max-width: 767px) {
        background: $skin-primary;
        text-align: center;
    }
}

.weekly-bonus-timer-wrapper {
    padding-left: 20px;

    @media (max-width: 767px) {
        padding-left: 0px;
    }
}

.weekly-bonus-expires-in {
    font-size: 12px;
    font-weight: 400;
    color: $white-87;
}

.weekly-bonus-timer {
    font-size: 15px;
    color: $white-100;
    font-weight: 500;
    font-family: 'Roboto Mono', monospace;
}