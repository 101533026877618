.category-link-icon-right {
    background-color: $skin-foreground;
    border-radius: $border-radius-category-link;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    flex-grow: 0.33;
    min-width: 350px;
    
    @include category-link-hover;
}

.category-link-icon-right:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .category-link-icon-right {
        min-width: 320px;
        margin-right: 0px;
        justify-content: space-between;
        padding: 15px 30px;
    }

    .category-link-icon-right:last-child {
        margin-bottom: 0;
    }
}

.category-link-icon-right-title {
    font-weight: 500;
}

.category-link-icon-right-image {
    height: 35px;
    width: auto;
}
