//@import '../scss/colors.scss';

$navbar-height-desktop: 56px;
$navbar-height-mobile: 56px;
$navbar-height-mobile-logged-in: 21px;

.games-categories-container {
    padding-left: 0;
    padding-right: 0;
    min-height: 450px;
}

.games-banner-image {
    position: absolute;
    top: -65px;
    left: 0;
    width: 100%;
    height: 150px;
    //background-image: url('../data/images/background-games.png');
    background-image: url('https://cherryspins.b-cdn.net/images/background-games.png');
    background-position: top center;
    background-size: cover;
    z-index: -1;
    opacity: 1;
}

@media (max-width: 767px) {
    .games-banner-image {
        // position: absolute;
        // top: -65px;
        // left: 0;
        // width: 100%;
        // height: 150px;
        // background-image: url('../data/images/background-games-mobile.png');
        // background-position: top center;
        // background-size: cover;
        // z-index: -1;
        // opacity: 1;
        background-image: none;
    }
}

.games-categories-loading {
    display: flex;
    height: 300px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.games-search-wrapper {
    position: relative;
    padding: 30px 15px 15px;
    // background-color: $skin-background; //$skin-secondary;
    // background-image: url('https://ik.imagekit.io/vbh4oaoonf1/games/dragons_background_PKNQx3eKs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1661969971602');
//     background: -moz-linear-gradient(180deg, rgba(39,39,39,1) 0%, rgba(18,18,18,1) 100%);
// background: -webkit-linear-gradient(180deg, rgba(39,39,39,1) 0%, rgba(18,18,18,1) 100%);
// background: linear-gradient(180deg, rgba(39,39,39,1) 0%, rgba(18,18,18,1) 100%);
    background-position: center center;
    background-size: cover;
    // border-top: 1px solid $skin-secondary;
}

@media (max-width: 767px) {
    .games-search-wrapper {
        padding: 0.75rem 0.75rem;
    }
}

.games-search-field {
    position: relative;
    max-width: 480px;
    margin: auto;
}

.games-search-field > input {
    background-color: $skin-background;
    background-color: transparent !important;
    border-radius: 0px !important;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-bottom: 1px solid $disabled-text;
}

.games-search-field > input:focus {
    box-shadow: none;
    border-color: $high-emphasis;
}

input[type="search"]::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  
    /* Now your own custom styles */
     height: 14px;
     width: 14px;
     display: block;
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;
  
    /* icon size */
    background-size: 14px;
    color: $white-100;
  }

.games-search-field-icon {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 22px;
    color: $disabled-text;
    z-index: 1002;
}

.swoosh {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.games-filter-container {
    padding: 15px 0;
}

.games-filter-container-fixed {
    position: sticky;
    position: -webkit-sticky;
    top: $navbar-height-desktop;
    left: 0;
    width: 100%;
    z-index: 1003;
    background-color: $skin-background;
    border-bottom: 1px solid $skin-background;
    padding: 10px 0;
}

@media (max-width: 575px) {
    .games-filter-container-fixed {
        top: $navbar-height-mobile;
    }

    .games-filter-container,
    .games-filter-container-fixed {
        padding: 10px 7px;
    }
}

.games-filter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.games-filter::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.games-filter {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media (max-width: 991px) {
    .games-filter {
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767px) {
    .games-logged-in-top {
        top: $navbar-height-mobile-logged-in;
    }
}

.games-category {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    text-align: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
    // background-color: $skin-secondary;
    // background-color: $skin-background;
    color: $black;
    color: $mid-emphasis;
    font-size: 0.875rem;
    border-radius: 25px;
    margin: 0 0.275rem;
    white-space: nowrap;
    border: none;
}

.games-category-placeholder {
    font-size: 0.875rem;
    border-radius: 25px;
    margin: 0 0.375rem;
    border: none;
    width: 100px;
    height: 37px;
}

.games-category:hover,
.games-category:active {
    color: $high-emphasis;
    border: none;
}

@media (max-width: 576px) {
    .games-category {
        font-size: 13px;
        padding: 0.5rem 0.75rem;
    }

    .games-category-placeholder {
        height: 34px;
    }
}

.games-category-active {
    background-color: $skin-foreground;
    color: $high-emphasis;
    font-weight: 600;
}

.games-category-active:hover {
    background-color: $skin-foreground;
    color: $white;
}