.freespins-row-container {
    background: linear-gradient(90deg, rgba(58,58,58,1) 0%, rgba(39,39,39,1) 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: $border-radius-card;
    text-decoration: none !important;
}

@media (max-width: 767px) {
    .freespins-row-container {
        flex-direction: column;
        // align-items: flex-start;
    }
}

.freespins-row-image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-card;
}

@media (max-width: 767px) {
    .freespins-row-image-wrapper  {
        width: 100%;
        text-align: center;
        padding-top: 20px;
    }
}

.freespins-row-image {
    position: relative;
    height: $promo-image-height;
    width: auto;
    border-radius: $border-radius-card;
    z-index: 1;
}

@media (max-width: 767px) {
    .freespins-row-image {
        height: $promo-image-height;
    }
}

.freespins-row-text-wrapper {
    padding-left: 30px;
}

@media (max-width: 767px) {
    .freespins-row-text-wrapper {
        padding: 15px;
        text-align: center;
    }
}

.freespins-row-text-gold {
    color: $gold;
    white-space: nowrap;
}

.freespins-row-button-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

@media (max-width: 767px) {
    .freespins-row-button-wrapper {
        flex-grow: 1;
        display: flex;
        width: 100%;
        padding-right: 0px;
        padding-bottom: 20px;
        justify-content: center;
    }
}

.freespins-row-icon {
    font-size: 20px;
    color: $white-60;
}