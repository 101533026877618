.limited_free_bonus-note {
    padding: 3px 8px;
    background: #3a3a3a;
    border-radius: 10px;
}

.limited_free_bonus-lock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.limited_free_bonus-buttons {
    @media (max-width:767px) {
        margin-top: 20px !important;
    }
}