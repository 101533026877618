// @import '../scss/colors.scss';

.fallback-meter {
    height: 4px;
    position: relative;
    background: $skin-background;
    overflow: hidden;
}

.fallback-meter span {
    display: block;
    height: 100%;
    width: 100%;
}

.fallback-progress {
    background-color: $skin-foreground;
    animation: progressBar 0.2s ease-in-out;
    animation-fill-mode: both;
}

@keyframes progressBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}