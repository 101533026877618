//@import '../scss/colors.scss';

.all-games-row-squares-title-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 110px;
}

@media (max-width: 991px) {
    .all-games-row-squares-title-wrapper {
        margin-right: 15px;
    }
}

.all-games-row-squares-title-link {
    font-weight: 500;
}

.all-games-row-squares-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    order: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.all-games-row-squares-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.all-games-row-squares-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

// @media (max-width: 767px) {
//     .all-games-row-squares-wrapper {
//         grid-template-columns: 1fr;
//         grid-row-gap: 10px;
//     }
// }

.all-games-row-square-placeholder {
    height: 50px;
    border-radius: 10px;
    flex-grow: 1;
}

.all-games-tile-container {
    background-color: $dp02;
    border-radius: 40px;
    padding: 20px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    flex-grow: 0.33;
    min-width: 300px;
}

.all-games-tile-container:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .all-games-tile-container {
        margin-right: 0px;
        padding: 15px 20px;
        justify-content: flex-start;
        border-radius: 10px;
    }

    .all-games-tile-container:last-child {
        margin-bottom: 0;
    }
}

.all-games-tile-image-wrapper {
    width: 100px;
    text-align: center;
    margin-right: 15px;
}

.all-games-tile-image {
    height: 35px;
    width: auto;
}

.all-games-tile-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.all-games-tile-text {
    color: $high-emphasis;
    margin: 0;
    font-size: 0.875rem;
}

@media (min-width: 768px) {
    .all-games-tile-container:hover {
        background-color: $dp04;
    }
    
    .all-games-tile-container:hover .all-games-tile-text {
        color: $high-emphasis;
    }
}

@media (max-width: 767px) {
    .all-games-tile-container:active {
        background-color: $dp04;
    }
    
    .all-games-tile-container:active .all-games-tile-text {
        color: $high-emphasis;
    }
}

