@mixin font-settings {
    line-height: 150%;
    letter-spacing: 0.0012em;
    font-style: normal;
}

////////////////

.h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $high-emphasis;
}

@media (max-width: 991px) {
    .h1 {
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.0012em;
    }
}

@media (max-width: 767px) {
    .h1 {
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.0012em;
    }
}

.h2 {
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $high-emphasis;
}

@media (max-width: 767px) {
    .h2 {
        font-size: 21px;
        line-height: 150%;
        letter-spacing: 0.0012em;
    }
}

.h3 {
    font-weight: 500;
    font-size: 21px;
    line-height: 150%;
    letter-spacing: 0.0012em;
    // margin-bottom: 0;
    // margin-top: 0;
    color: $high-emphasis;
}

@media (max-width: 767px) {
    .h3 {
        // font-weight: 600;
        font-size: 19px;
    }
}

// .h3.h3_size_20 {
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 150%;
//     letter-spacing: 0.0012em;
// }

.h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $high-emphasis;
}

// @media (max-width: 767px) {
//     .h4 {
//         font-weight: 500;
//         font-size: 16px;
//     }
// }

.h4.h4-bold {
    font-weight: 600;
}

.h5 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $high-emphasis;
}

p, li {
    font-size: 0.875rem;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $mid-emphasis;
}

a {
    font-size: 0.875rem;
    line-height: 150%;
    letter-spacing: 0.0012em;
    color: $high-emphasis;
}

a:hover,
a:focus,
a:active {
    color: $white;
}

.mid-emphasis {
    color: $mid-emphasis;
}

.high-emphasis {
    color: $high-emphasis;
}

.disabled-text {
    color: $disabled-text;
}

/////////////////

.title-xl {
    @include font-settings;
    font-weight: 600;
    font-size: 44px;

    @media (max-width: 1199px) {
        font-size: 24px;
    }
}

.title {
    @include font-settings;
    font-weight: 600;
    font-size: 32px;

    @media (max-width: 1199px) {
        font-size: 24px;
    }
}

.title-xs {
    @include font-settings;
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 1199px) {
        font-size: 18px;
    }
}

.subtitle-xl {
    @include font-settings;
    font-weight: 500;
    font-size: 21px;

    @media (max-width: 1199px) {
        font-size: 16px;
    }
}

.subtitle {
    @include font-settings;
    font-weight: 500;
    font-size: 18px;

    @media (max-width: 1199px) {
        font-size: 16px;
    }
}

.subtitle-sm {
    @include font-settings;
    font-weight: 500;
    font-size: 16px;
}

.subtitle-xs {
    @include font-settings;
    font-weight: 400;
    font-size: 15px;
}

.tag-xl {
    @include font-settings;
    font-weight: 600;
    font-size: 15px;
}