//@import '../scss/colors.scss';

.deposit-container {

    .deposit-section {
        padding: 20px;
        //background-color: $skin-foreground-2x;
        background-color: $skin-foreground;
        border-radius: 10px;
        margin-bottom: 20px;

    }

    @media (max-width: 767px) {
        .deposit-section {
            padding: 15px;
        }
    }

    .btn-bonus {
        text-align: left;
        border-radius: 4px;
        font-weight: 400;

        background: $dp08;
        border: 1px solid transparent;

        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .btn-bonus:active,
    .btn-bonus:focus,
    .btn-bonus:hover {
        background: $skin-supplementary;
        border: 1px solid transparent;
    }

    .btn-bonus.active {
        background: $skin-supplementary-dark;
        border: 1px solid $skin-supplementary-dark;
    }

    .bonus-title {
        font-weight: 500;
        font-size: 14px;
        color: $high-emphasis;
    }

    .bonus-subtitle {
        font-size: 12px;
    }

    ////////////////////////
    /// Amounts
    //////////////////////
    .preset-amounts {
        display: flex;
    }

    .btn-preset-amounts {
        border-radius: 4px;
        font-weight: 500;
        flex-grow: 1;
        margin: 0 0.125rem;
        margin-bottom: 0.5rem;

        color: $high-emphasis;
        background: #3a3a3a;
        border: 2px solid transparent;
        font-size: 14px;

        // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .btn-preset-amounts:active,
    .btn-preset-amounts:focus,
    .btn-preset-amounts:hover {
        background: $skin-supplementary-dark;
        border: 2px solid transparent;
        outline: none;
    }

    .btn-preset-amounts.active {
        background: $skin-supplementary-dark;
        border: 1px solid $skin-supplementary-dark;
        border: 2px solid #25c0cd;
    }

    ////////////////////////
    /// Deposit button
    //////////////////////
    .btn-deposit-container {
        text-align: center;
        margin-top: 20px;
    }

    .btn-deposit-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .btn-deposit {
        position: relative;
    }

    ////////////////////////
    /// Accordions
    //////////////////////
    .accordion-item {
        border: none;
    }

    .accordion-body {
        padding: 0;
        padding-top: 15px;
    }

    .accordion-button {
        padding: 0;
    }

    .accordion-item {
        background-color: transparent;
    }

    .accordion-button,
    .accordion-button:not(.collapsed) {
        background-color: transparent;
    }

    ////////////////////////
    /// Iframe step
    //////////////////////
    .deposit-iframe-section {
        min-height: 400px;
        display: flex;
        flex-direction: column;
    }

    .deposit-iframe-header {
        display: flex;
        align-items: center;
    }

    .deposit-iframe-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-content: center;
        flex-grow: 1;
    }

    .payment-container {
        display: flex;
        justify-items: center;
        width: 100%;
        // min-width: 700px;
    }

    // @media (max-width: 767px) {
    //     .deposit-section {
    //         min-width: 100%;
    //     }
    // }

    .payment-container-hidden {
        visibility: 0;
        height: 0;
        opacity: 0;
    }

    .btn-deposit-back {
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 400;
    }

    ////////////////
    .deposit-icons-container {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .deposit-icons {
        width: 90%;
        max-width: 450px;
        opacity: 0.6;
    }

    .deposit-loading-dots:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
    }

    @keyframes dots {

        0%,
        20% {
            color: rgba(0, 0, 0, 0);
            text-shadow:
                .25em 0 0 rgba(0, 0, 0, 0),
                .5em 0 0 rgba(0, 0, 0, 0);
        }

        40% {
            color: $mid-emphasis;
            text-shadow:
                .25em 0 0 rgba(0, 0, 0, 0),
                .5em 0 0 rgba(0, 0, 0, 0);
        }

        60% {
            text-shadow:
                .25em 0 0 $mid-emphasis,
                .5em 0 0 rgba(0, 0, 0, 0);
        }

        80%,
        100% {
            text-shadow:
                .25em 0 0 $mid-emphasis,
                .5em 0 0 $mid-emphasis;
        }
    }

}


/////////////
/// Deposit New
////////////
.bonus-section-title-wrapper {
    margin-bottom: 11px;
}

.bonus-section-subtitle {
    @media (min-width: 992px) {
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.bonus-card {
    position: relative;
    background: #3a3a3a;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    border: 2px solid #272727;
    padding: 0px;
    text-align: left;
    // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.bonus-card:hover,
.bonus-card:active,
.bonus-card:focus {
    background: #094b4f;
    border: 2px solid #272727;
    outline: none !important;
    box-shadow: none !important;

    @media (max-width: 767px) {
        background: #3a3a3a;
    }
}

.bonus-card-selected,
.bonus-card-selected:hover,
.bonus-card-selected:active,
.bonus-card-selected:focus {
    background: #0a565c;
    border: 2px solid $personalized-text;
}

.bonus-card img {
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.bonus-card-text-wrapper {
    padding: 8px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.bonus-card-title {
    // margin: 12px 10px 0 10px;
    color: $white-100;
    font-weight: 500;
    // text-align: center;
}

.btn-bonus-select {
    margin: 0px 10px 10px 10px;
}

.icon-bonus-selected {
    font-size: 18px;
}

.bonus-card-check {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #26c0cd;
    padding: 3px 7px 3px 9px;
    border-radius: 0 5px 0 10px;
}