@import '../scss/colors.scss';

.load-more-container {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}

.btn-load-more {
    border: 1px solid $disabled-text !important;
}