.background-container-home {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 300px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
    background: -moz-linear-gradient(0deg, $skin-background 0%, $skin-foreground 100%);
    background: -webkit-linear-gradient(0deg, $skin-background 0%, $skin-foreground 100%);
    background: linear-gradient(0deg, $skin-background 0%, $skin-foreground 100%);
}

.background-container-games {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 150px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
    background: -moz-linear-gradient(0deg, $skin-background 0%, $skin-foreground 100%);
    background: -webkit-linear-gradient(0deg, $skin-background 0%, $skin-foreground 100%);
    background: linear-gradient(0deg, $skin-background 0%, $skin-foreground 100%);
}