$img-container-min-height: 215px;

@mixin scale {
    @media (max-width: 1199px) {
        transform: scale(85%);
    }

    @media (max-width: 991px) {
        transform: scale(55%);
    }

    @media (max-width: 390px) {
        transform: scale(48%);
    }
}

.home-banner-logout-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    background-position: center center;
    background-size: cover;
    padding: 30px 60px;
    background: $skin-foreground;
    border-radius: $border-radius-card;

    @media (max-width: 991px) {
        flex-direction: column-reverse;
        padding: 10px 15px 15px 15px;
        text-align: center;
    }
}

.grey-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: $border-radius-card;
    opacity: 0;
    background: linear-gradient(145deg, rgba(39, 39, 39, 1) 35%, rgba(76, 76, 76, 1) 100%);
    transition: all 0.3s ease-in-out;
    z-index: -1;

    @media (max-width: 991px) {
        background: linear-gradient(150deg, rgba(76, 76, 76, 1) 0%, rgba(39, 39, 39, 1) 50%);
    }
}

.red-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: $border-radius-card;
    opacity: 0;
    background: linear-gradient(145deg, rgba(39, 39, 39, 1) 35%, rgba(222, 55, 73, 1) 80%);
    transition: all 0.3s ease-in-out;
    z-index: -1;

    @media (max-width: 991px) {
        background: linear-gradient(160deg, rgb(222, 55, 73) 26%, rgb(39, 39, 39) 63%)
    }
}

.gradient-opacity-1 {
    opacity: 1;
}

.home-banner-logout-content-wrapper,
.home-banner-logout-image-wrapper {
    flex-grow: 1;
    flex-basis: 50%;

    @media (max-width: 991px) {
        flex-grow: 1;
        flex-basis: 100%;
    }
}

.home-banner-logout-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.home-banner-logout-text {
    margin-top: 30px;
    margin-bottom: 100px;

    @media (max-width: 991px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.home-banner-logout-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;

    @media (max-width: 991px) {
        margin-top: 23px;
        justify-content: center;
    }
}

// .home-banner-logout-logos {
//     display: none;

//     @media (min-width: 992px) {
//         display: block;
//     }
// }

.home-banner-logout-award {
    height: 70px;
    margin-right: 20px;

    @media (max-width: 991px) {
        height: 35px;
        opacity: 0.87;
    }
}

.home-banner-logout-psp {
    height: 30px;

    @media (max-width: 991px) {
        height: 20px;
        opacity: 0.87;
    }
}

.home-banner-logout-image-wrapper {
    position: relative;

    @media (max-width: 991px) {
        width: 400px;
        margin: auto;
        min-height: 270px;
    }

    @media (max-width: 540px) {
        width: 100%;
    }

    @media (max-width: 390px) {
        min-height: $img-container-min-height;
    }
}

.home-banner-logout-image-scale {
    position: relative;
    height: 100%;
    width: 100%;
    right: 80px;

    @media (max-width: 1399px) {
        right: 0;
    }

    @media (max-width: 1199px) {
        min-height: 255px;
        right: -15px;
    }

    @media (max-width: 991px) {
        right: 60px;
    }

    @media (max-width: 390px) {
        min-height: $img-container-min-height;
    }
}

.image-chest-closed {
    position: absolute;
    height: 200px;
    bottom: 10px;
    right: -20px;

    @media (max-width: 1199px) {
        height: 200px;
        bottom: 0px;
        right: 60px;
    }

    @media (max-width: 991px) {
        height: 100px;
        bottom: -40px;
        right: 10px;
    }

    @media (max-width: 390px) {
        height: 100px;
        bottom: -20px;
        right: 20px;
    }
}

.image-chest-opened {
    position: absolute;
    height: 550px;
    bottom: -56px;
    right: -120px;
    z-index: 0;
    transform-origin: 80% 60%;

    @media (max-width: 1199px) {
        height: 465px;
        bottom: -56px;
        right: -50px;
    }

    @media (max-width: 991px) {
        height: 280px;
        bottom: -47px;
        right: -50px;
    }

    @media (max-width: 390px) {
        height: 250px;
        bottom: -32px;
        right: -37px;
    }
}

.image-chest-opened-mobile-animated {
    position: absolute;
    z-index: 0;
    height: 255px;
    width: 465px;
    top: 10px;
    right: -30px;

    @media (max-width: 390px) {
        height: 226px;
        width: 400px;
        top: -0px;
        right: -30px;
    }
}

.image-hidden {
    visibility: hidden !important;
}

.image-bigbass,
.image-bonanza,
.image-wolfgold,
.image-doghouse,
.image-olympus,
.image-discount {
    position: absolute;
    z-index: 2;
}

.image-bonanza {
    right: 64px;
    bottom: 20px;
    height: 247px;

    @media (max-width: 1199px) {
        right: 103px;
        bottom: 23px;
        height: 210px;
    }

    @media (max-width: 991px) {
        right: 31px;
        bottom: -6px;
        height: 146px;
    }

    @media (max-width: 390px) {
        right: 22px;
        bottom: 5px;
        height: 130px;
    }
}

.image-bigbass {
    right: -61px;
    bottom: 103px;
    height: 380px;

    @media (max-width: 1199px) {
        right: -18px;
        bottom: 103px;
        height: 320px;
    }

    @media (max-width: 991px) {
        right: -30px;
        bottom: 67px;
        height: 183px;
    }

    @media (max-width: 390px) {
        right: -28px;
        bottom: 60px;
        height: 158px;
    }
}

.image-doghouse {
    right: 217px;
    bottom: -12px;
    height: 205px;

    @media (max-width: 1199px) {
        right: 211px;
        bottom: 0px;
        height: 170px;
    }

    @media (max-width: 991px) {
        right: 108px;
        bottom: -28px;
        height: 128px;
    }

    @media (max-width: 390px) {
        right: 90px;
        bottom: -28px;
        height: 111px;
    }
}

.image-wolfgold {
    right: -125px;
    bottom: 44px;
    height: 235px;

    @media (max-width: 1199px) {
        right: -64px;
        bottom: 44px;
        height: 190px;
    }

    @media (max-width: 991px) {
        right: -71px;
        bottom: 7px;
        height: 131px;
    }

    @media (max-width: 390px) {
        right: -65px;
        bottom: 8px;
        height: 124px;
    }
}

.image-olympus {
    right: 184px;
    bottom: 129px;
    height: 342px;

    @media (max-width: 1199px) {
        right: 184px;
        bottom: 129px;
        height: 290px;
    }

    @media (max-width: 991px) {
        right: 105px;
        bottom: 68px;
        height: 178px;
    }

    @media (max-width: 390px) {
        right: 103px;
        bottom: 63px;
        height: 151px;
    }
}

.image-discount {
    left: 0;
    top: 0;
    height: 100px;
    border-radius: $border-radius-card 0 0 0;

    @media (max-width: 991px) {
        height: 80px;
    }
}

@keyframes pulse_custom {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.animate__pulse_custom {
    -webkit-animation-name: pulse_custom;
    animation-name: pulse_custom;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.animate__animated.animate__faster_custom {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}