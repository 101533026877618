/* Design variables */
$border-radius-card: 20px;
$border-radius-game: 10px;
$border-radius-category-link: 40px;
$promo-image-height: 140px;

/* Mixins */
@mixin category-link-hover {
    transition: all 0.1s ease-in;

    &:hover {
        background-color: $skin-foreground-2x;
    }
}
