.total-jackpot {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    background-color: $skin-foreground;
    border-radius: 10px;
    padding: 40px 20px;
    margin-top: 30px;

    // background-image: url('https://cherryspins.b-cdn.net/images/total-jackpot.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 1199px) {
    .total-jackpot {
        // background-image: url('https://cherryspins.b-cdn.net/images/total-jackpot-md.jpg');
    }
}

// @media (max-width: 991px) {
//     .total-jackpot {
        
//     }
// }


@media (max-width: 767px) {
    .total-jackpot {
        flex-direction: column;
        justify-content: flex-end;
        background-position: center top;
        padding: 20px;
        height: 705px;
        background-image: url('https://cherryspins.b-cdn.net/images/total-jackpot-xs.jpg');
        background-size: contain;
    }
}

@media (max-width: 700px) {
    .total-jackpot {
        height: 650px;
    }
}

@media (max-width: 600px) {
    .total-jackpot {
        height: 600px;
    }
}

@media (max-width: 500px) {
    .total-jackpot {
        height: 500px;
    }
}

@media (max-width: 450px) {
    .total-jackpot {
        height: 465px;
    }
}

@media (max-width: 390px) {
    .total-jackpot {
        height: 450px;
    }
}

// .payment-providers-images {
//     height: 40px;
// }

// @media (max-width: 991px) {
//     .payment-providers-images {
//         margin-bottom: 10px;
//     }
// }

// .payment-providers-images img {
//     height: 40px;
// }

.total-jackpot-text {
    flex-basis: 40%;
    text-align: center;
}

@media (max-width: 767px) {
    .total-jackpot-text {
        flex-basis: auto;
    }
}

.jackpot-sum {
    font-size: 40px;
    text-align: left;
    width: 298px;
    margin: auto;
}

// .total-jackpot-image {
//     position: absolute;
//     left: 0;
//     top: 0;
// }

// @media (max-width: 991px) {
//     .payment-providers-text {
//         margin-right: 0px;
//     }
// }

// .payment-providers-text * {
//     margin-bottom: 0;
//     font-weight: 400;
//     font-size: 0.875rem;
// }

// @media (max-width: 991px) {
//     .payment-providers-text * {
//         text-align: center;
//     }
// }