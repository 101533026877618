.game_tile_live-minmax_bet {
    position: absolute;
    bottom: 7px;
    left: 7px;
    background: rgb(0 0 0 / 60%);
    color: $white;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 6px;
}

.game_tile_live-players {
    position: absolute;
    bottom: 7px;
    right: 7px;
    background: rgb(0 0 0 / 60%);
    color: $white;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 6px;
}

.game_tile_result-scibo,
.game_tile_result-baccarat,
.game_tile_result-blackjack {
    position: absolute;
    top: 7px;
    right: 7px;
    background: rgb(0 0 0 / 60%);
    color: $white;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 6px;
}

.game_tile_result-roulette {
    position: absolute;
    top: 7px;
    right: 7px;
    color: $white;
    font-size: 12px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
}

.roulette-win {
    &-red {
        background: red;
    }

    &-black {
        background: black;
    }

    &-green {
        background: green;
    }
}