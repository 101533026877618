@import '../scss/colors.scss';

.home-banner-container {
    display: flex;
    position: relative;
    align-items: center;
    background-position: center center;
    background-size: cover;
    padding-left: 0;
    padding-right: 0;
}

@media (max-width: 767px) {
    .home-banner-container::after {
        width: 390px;
        height: 78px;
    }
}

.home-banner-image {
    position: absolute;
    top: -65px;
    left: 0;
    width: 100%;
    height: 600px;
    background-image: url('https://cherryspins.b-cdn.net/images/background.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
}

@media (max-width: 767px) {
    .home-banner-image {
        background-image: url('https://cherryspins.b-cdn.net/images/background-mobile.png');
        background-size: contain;
    }
}

.home-banner-wrapper {
    padding-top: 50px;
    padding-bottom: 20px;
}

@media (max-width: 767px) {
    .home-banner-wrapper {
        padding-top: 25px;
        padding-bottom: 0px;
    }
}

.home-banner-text {
    color: $black;
    max-width: 728px;
    margin: auto;
    text-align: center;
}

.home-banner-award {
    text-align: center;
    margin-top: 20px;
    margin-bottom: -25px;
}

@media (max-width: 767px) {
    .home-banner-award {
        margin-bottom: 0px;
    }
}

.home-banner-award img {
    max-height: 70px;
    opacity: 0.65;
}
