//@import '../scss/colors.scss';

.withdraw-container {

    .withdraw-section {
        padding: 20px;
        background-color: $skin-foreground;
        border-radius: 10px;
        margin-bottom: 20px;

    }

    @media (max-width: 767px) {
        .withdraw-section {
            padding: 15px;
        }
    }

    .btn-withdraw-back {
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 400;
    }

    .btn-withdraw-container {
        text-align: center;
    }

    .withdraw-iframe-section {
        min-height: 400px;
        display: flex;
        flex-direction: column;
    }

    .withdraw-iframe-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-content: center;
        flex-grow: 1;
    }

    .withdraw-container {
        display: flex;
        justify-items: center;
        width: 100%;
    }

    .withdraw-container-hidden {
        visibility: 0;
        height: 0;
        opacity: 0;
    }

    ////////////////////////
    /// Accordions
    //////////////////////
    .accordion-item {
        background-color: $skin-foreground;
        color: $mid-emphasis;
        font-size: 0.875rem;
    }

    .accordion-body {
        background-color: $skin-foreground;
        padding: 1.5rem 1.25rem;
    }

    .accordion-button,
    .accordion-button:not(.collapsed) {
        background-color: $skin-foreground;
        color: $high-emphasis;
        font-size: 0.875rem;
        padding: 25px 20px;
    }

    .accordion-item:first-of-type .accordion-button,
    .accordion-item:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .accordion-item:last-of-type .accordion-button.collapsed,
    .accordion-item:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    ////////////////
    .withdrawal-icons-container {
        display: flex;
        justify-content: center;
        margin-top: -5x;
        margin-bottom: 30px;
    }

    .withdrawal-icons {
        width: 90%;
        max-width: 350px;
        opacity: 0.6;
    }

    ////////////////////////
    /// Accordions
    //////////////////////
    .withdrawal-loading-dots:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
    }

    @keyframes dots {
        0%,
        20% {
            color: rgba(0, 0, 0, 0);
            text-shadow:
                .25em 0 0 rgba(0, 0, 0, 0),
                .5em 0 0 rgba(0, 0, 0, 0);
        }

        40% {
            color: $mid-emphasis;
            text-shadow:
                .25em 0 0 rgba(0, 0, 0, 0),
                .5em 0 0 rgba(0, 0, 0, 0);
        }

        60% {
            text-shadow:
                .25em 0 0 $mid-emphasis,
                .5em 0 0 rgba(0, 0, 0, 0);
        }

        80%,
        100% {
            text-shadow:
                .25em 0 0 $mid-emphasis,
                .5em 0 0 $mid-emphasis;
        }
    }
}