.must-drop-jackpot-row-wrapper {
    grid-template-columns: 280px auto;

    @media (max-width: 767px) {
        grid-template-columns: 230px auto;
        min-height: 244px;
    }
}

.must-drop-jackpot-row-wrapper > div:first-child {
    margin-right: 0;
}

.title-placeholder {
    @media (min-width: 992px) {
        margin-top: 60px;
    }
}

.must-drop-jackpot-tile-container {
    display: flex;
    flex-direction: column;
    background-color: $skin-foreground;
    justify-content: space-between;
    border-radius: $border-radius-card;
    padding: 25px;

    @media (max-width: 992px) {
        padding: 15px;
    }
}

.must-drop-content-top {
    align-self: flex-start;
}

.must-drop-content-bottom {
    align-self: flex-end;
    position: relative;
    text-align: right;
}

.daily-drop {
    background: linear-gradient(135.31deg, #272727 55%, #0D7077 100%);
}

.hourly-drop {
    background: linear-gradient(135.31deg, #272727 55%, #9139D7 100%);
}

.must-drop-timer-lable {
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: $white-87;
}

.must-drop-timer {
    font-weight: 500;
    font-size: 19px;
    color: $white-100;
    font-family: 'Roboto Mono', monospace;
    // text-align: left;
    // margin-left: auto;
    // margin-right: -5px;
}
