////////////////////////
/// Row scroll arrow
//////////////////////
.arrow-right,
.arrow-left {
    display: none;
    position: absolute;
    top: calc(50% - 55px);
    right: -35px;
    padding: 13px 24px;
    border-radius: 50%;
    font-size: 1.5rem;
    z-index: 1002;

    background-color: $skin-foreground;
    // box-shadow: 0 0 15px 5px rgb(0 0 0 / 20%);
    color: $mid-emphasis;
    font-weight: 700;
    border: 1px solid transparent;

    top: -48px;
    right: 0px;
    padding: 6px 12px;
    font-size: 1rem;
}

.arrow-left {
    left: -35px;
    right: auto;

    right: 50px;
    left: auto;
}

.arrow-right:hover,
.arrow-left:hover,
.arrow-right:focus,
.arrow-left:focus,
.arrow-right:active,
.arrow-left:active {
    background-color: $skin-foreground;
    color: $high-emphasis;
    border: 1px solid transparent;
}

.arrow-right:active,
.arrow-left:active {
    transform: scale(0.95);
}

/* Hiding the arrows on mobile */
@media (min-width: 992px) {
    .arrow-visible {
        display: block;
        opacity: 0.5;
    }

    .arrow-active {
        opacity: 1;
    }
}